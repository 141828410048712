import { useEffect, useState } from "react";
import { Attachment } from "hooks/useAlerts";
import {
  BsChevronLeft,
  BsChevronRight,
  BsFiletypePdf,
  BsX,
} from "react-icons/bs";
import * as Dialog from "@radix-ui/react-dialog";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

interface ImagePreviewModalProps {
  attachments: Attachment[];
  index: number;
}

export default function ImagePreviewModal({
  attachments,
  index,
}: ImagePreviewModalProps) {
  const [currentIndex, setCurrentIndex] = useState(index);
  const [media, setMedia] = useState(attachments[index]);

  const handleNextImage = (e: any) => {
    if (e.key === "ArrowRight" && currentIndex < attachments.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    }
    if (e.key === "ArrowLeft" && currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  useEffect(() => {
    setMedia(attachments[currentIndex]);
  }, [currentIndex]);

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          inset: 0,
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: "50",
        }}
      />
      <Dialog.Content
        forceMount
        onKeyDown={handleNextImage}
        className={
          "z-[100] wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed flex flex-col items-center gap-5 rounded-lg font-comfortaa w-[70vw] md:w-[30vw] max-h-[90vh]"
        }
      >
        <Dialog.Close
          className={
            "z-[101] absolute top-2 right-2 rounded-full bg-error hover:opacity-70"
          }
        >
          <BsX color="#fff" size={20} />
        </Dialog.Close>
        <div className="w-full h-full relative contents">
          {media.file_type === "image" && (
            <TransformWrapper pinch={{ disabled: false }}>
              <TransformComponent>
                <img
                  className="w-full h-full object-contain max-h-full"
                  src={media.file_name}
                />
              </TransformComponent>
            </TransformWrapper>
          )}
          {media.file_type === "video" && (
            <video className="w-full h-full object-cover max-h-[90vh]" controls>
              <source
                src={media.file_name}
                type="video/mp4"
                className="w-full"
              />
              Seu navegador não suporta o elemento de vídeo.
            </video>
          )}
          {media.file_type === "pdf" && (
            <div className="w-full h-full min-w-[30vw] min-h-[70vh] max-h-full relative rounded-md font-comfortaa bg-gray-200 flex items-center justify-center">
              <a
                href={media.file_name}
                target="_blank"
                className="hover:opacity-70"
              >
                <BsFiletypePdf className="text-5xl text-azulfy-blue" />
              </a>
            </div>
          )}
          {currentIndex < attachments.length - 1 && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setCurrentIndex((prev) => prev + 1);
              }}
              className="z-[101] absolute top-[50%] -translate-y-[50%] right-4 rounded-full bg-azulfy-blue p-1 flex items-center justify-center hover:opacity-70"
            >
              <BsChevronRight size={24} color="#fff" />
            </button>
          )}

          {currentIndex > 0 && (
            <button
              onClick={() => setCurrentIndex((prev) => prev - 1)}
              className="z-[101] absolute top-[50%] -translate-y-[50%] left-4 rounded-full bg-azulfy-blue p-1 flex items-center justify-center hover:opacity-70"
            >
              <BsChevronLeft size={24} color="#fff" />
            </button>
          )}
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}